// Copyright 2024, Imprivata, Inc.  All rights reserved.

import { type Span } from '@opentelemetry/api';

export function stringifyObjectProperties(
  // eslint-disable-next-line
  attributes: Record<string, any>,
): Record<string, string> {
  const resultObject: Record<string, string> = {};
  Object.entries(attributes).forEach(([key, value]) => {
    // do not stringify primitive types
    if (['string', 'boolean', 'number'].includes(typeof value)) {
      resultObject[key] = value;
    } else {
      resultObject[key] = JSON.stringify(value);
    }
  });
  return resultObject;
}

export function getB3Descriptor(span: Span): string {
  // https://github.com/openzipkin/b3-propagation#sampling-state-2
  const sampled = '1';
  // eslint-disable-next-line
  // @ts-ignore
  const { traceId, spanId } = span._spanContext;

  return `${traceId as string}-${spanId as string}-${sampled as string}`;
}

export function getXRayDescriptor(span: Span): string {
  const TRACE_ID_FIRST_PART_LENGTH = 8;
  const sampled = '1';
  const context = span.spanContext();

  const traceId = context.traceId;
  const timestamp = traceId.substring(0, TRACE_ID_FIRST_PART_LENGTH);
  const randomNumber = traceId.substring(TRACE_ID_FIRST_PART_LENGTH);
  const parentId = context.spanId;
  return `Root=${sampled}-${timestamp}-${randomNumber};Parent=${parentId}`;
}
