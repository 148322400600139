// Copyright 2024, Imprivata, Inc.  All rights reserved.

import { type ExportResult, ExportResultCode } from '@opentelemetry/core';
import {
  type SpanExporter,
  type ReadableSpan,
} from '@opentelemetry/sdk-trace-base';

/*
 * Custom exporter that stores all tracing spans on the window object
 * so that tracing can be tested with CITs
 *
 * TODO: is expected to be of no use when mockserver is migrated to Express
 */
export class CITsSpansCollector implements SpanExporter {
  constructor() {
    // eslint-disable-next-line
    // @ts-ignore
    window._spans = [];
  }

  export(spans: ReadableSpan[], cb: (result: ExportResult) => void): void {
    // eslint-disable-next-line
    // @ts-ignore
    window._spans.push(...spans);
    cb({ code: ExportResultCode.SUCCESS });
  }

  async shutdown(): Promise<void> {
    await Promise.resolve();
  }
}
