// Copyright 2024, Imprivata, Inc.  All rights reserved.

import React from 'react';
import classes from './AppBackground.module.less';

export const AppBackground = (props: {
  title: string;
  children: React.JSX.Element;
}): React.JSX.Element => {
  const { title, children } = props;

  return (
    <div className={classes.appBackgroundContainer}>
      <div className={classes.titleContainer}>
        <div className="h1-header">{title}</div>
      </div>
      <div className={classes.authnModuleContainer}>{children}</div>
    </div>
  );
};
